import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  DateTimeInput,
  FormDataConsumer,
} from "react-admin";

import { getDeleteButton } from "./deleteResource";
import { CoordinateInput, CoordinateField } from "./coordinates";
import { DummyList } from "./dummy";

const LocationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const Title = ({ record }) => {
  return (
    <span>
      《{localStorage.getItem("scenarioName")}》地點
      {record && record.name ? `："${record.name}"` : ""}
    </span>
  );
};

const LocationDelete = (props) => {
  const paths = ["soundCenterId", "locationId", "geofenceCenter"];
  return getDeleteButton(props, "locations", "地點", paths.join(","));
};

export const LocationList = (props) => {
  if (localStorage.getItem("scenario")) {
    return (
      <List
        title={<Title />}
        {...props}
        sort={{ field: "name", order: "ASC" }}
        perPage="100"
        filters={<LocationFilter />}
      >
        <Datagrid>
          <TextField label="編號" source="rowIndex" />
          <TextField label="名稱" source="name" />
          <CoordinateField label="座標" source="coordinates" />
          <TextField label="說明" source="description" />
          <EditButton label="" />
          <LocationDelete label="" redirect={false} />
        </Datagrid>
      </List>
    );
  } else return DummyList(props);
};

export const LocationShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <CoordinateField source="coordinates" label="Coordinates" />
      <TextField multiline source="description" />
      <TextField source="createdate" />
      <TextField source="lastupdate" />
    </SimpleShowLayout>
  </Show>
);

export const LocationCreate = (props) => (
  <Create title={<Title />} {...props}>
    <SimpleForm>
      <TextInput label="名稱" source="name" />
      <FormDataConsumer>
        {({ formData }) => CoordinateInput(formData)}
      </FormDataConsumer>
      <TextInput label="說明" source="description" />
    </SimpleForm>
  </Create>
);

export const LocationEdit = (props) => (
  <Edit title={<Title />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <DateTimeInput label="建立時間" disabled source="createdate" />
      <DateTimeInput label="修改時間" disabled source="lastupdate" />
      <TextInput label="名稱" source="name" />
      <FormDataConsumer>
        {({ formData }) => CoordinateInput(formData)}
      </FormDataConsumer>
      <TextInput label="說明" source="description" />
    </SimpleForm>
  </Edit>
);
