// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Create,
  Edit,
  Filter,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  DateTimeInput,
  FileField,
  FileInput,
} from "react-admin";
import { getDeleteButton } from "./deleteResource";

import { DummyList } from "./dummy";
const SoundFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const Title = ({ record }) => {
  return (
    <span>
      《{localStorage.getItem("scenarioName")}》聲音
      {record && record.name ? `："${record.name}"` : ""}
    </span>
  );
};

const SoundDelete = (props) => {
  const paths = ["soundId"];
  return getDeleteButton(props, "sounds", "聲音", paths.join(","));
};

export const SoundList = (props) => {
  if (localStorage.getItem("scenario")) {
    return (
      <List
        title={<Title />}
        {...props}
        perPage="100"
        sort={{ field: "name", order: "ASC" }}
        filters={<SoundFilter />}
      >
        <Datagrid>
          <TextField label="編號" source="rowIndex" />
          <TextField label="名稱" source="name" />
          <FileField label="音檔" source="sound.src" title={"連結"} />
          <EditButton label="" />
          <SoundDelete label="" redirect={false} />
        </Datagrid>
      </List>
    );
  } else return DummyList(props);
};

export const SoundCreate = (props) => (
  <Create title={<Title />} {...props}>
    <SimpleForm>
      <TextInput label="名稱" source="name" />
      <FileInput label="音檔" source="sound">
        <FileField source="src" title="name" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export const SoundEdit = (props) => (
  <Edit title={<Title />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="名稱" source="name" />
      <FileInput label="音檔" source="sound">
        <FileField source="src" title="name" />
      </FileInput>
      <DateTimeInput label="建立時間" disabled source="createdate" />
      <DateTimeInput label="修改時間" disabled source="lastupdate" />
    </SimpleForm>
  </Edit>
);
